import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';



const Blog = () => {
return <div className="content">
    <Container>
        <Row md={1}>
            <Col>
                <h1>Blog</h1>
            </Col>
        </Row>
    </Container>
    <Container>
        <Row>
            <Col>
                <Card>
                    <Card.Img className="blog-preview-image" variant="bottom" src="../bp-mock-morning-routine.jpg" />
                    <Card.Body>
                        <Card.Title>Start the day right</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">Boost Your Morning Routine with These Essential Nutritional Supplements</Card.Subtitle>
                        <Card.Text>
                        A well-rounded morning routine sets the tone for a productive day ahead. Alongside a nutritious breakfast and energizing activities, incorporating certain nutritional supplements into your morning regimen can further enhance your physical performance and overall well-being. In this blog post, we will explore some key supplements that can help you start your day on the right foot.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated 3 mins ago</small>
                        <Card.Link href="#">Read More</Card.Link>
                    </Card.Footer>
                </Card>
         
                <Card >
                    <Card.Img className="blog-preview-image" variant="bottom" src="../bp1185231900.jpg" />
                    <Card.Body>
                        <Card.Title>Sleep Well</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            Enhance Your Sleep and Recovery with These Bedtime Nutritional Supplements
                        </Card.Subtitle>
                        <Card.Text>
                            Getting a good night's sleep is essential for overall well-being, recovery, and optimal performance. While a bedtime routine that includes relaxation techniques and a conducive sleep environment is crucial, certain nutritional supplements can further support a restful night's sleep. In this blog post, we will explore some key supplements to consider incorporating into your evening routine to promote better sleep and aid in recovery.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated 3 mins ago</small>
                        <Card.Link href="#">Read More</Card.Link>
                    </Card.Footer>
                </Card>

                <Card >
                    <Card.Img className="blog-preview-image" variant="bottom" src="../bp1295750034.jpg" />
                    <Card.Body>
                        <Card.Title>Intermittent Fasting</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">
                            Harnessing the Power of Intermittent Fasting for Effective Fat Loss
                        </Card.Subtitle>
                        <Card.Text>
                            Intermittent fasting has gained significant popularity in recent years as a powerful strategy for weight management and fat loss. Beyond simply restricting calorie intake, intermittent fasting involves cycling between periods of fasting and eating. In this blog post, we will delve into the science behind intermittent fasting and explore how it can be an effective tool to support your fat loss goals.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        <small className="text-muted">Last updated 3 mins ago</small>
                        <Card.Link href="#">Read More</Card.Link>
                    </Card.Footer>
                </Card>
            </Col>
        </Row>

    </Container>
    </div>;
};

export default Blog;