import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useEffect} from "react";

const TrainingLocation = () => {

    useEffect(() => {
        document.title = 'Iron & Fury Fitness - Training Locations';
        document.description = 'In-person personal training at your home or our private gym located between Flatonia and Moulton Texas in northwest Lavaca County.';
    }, []);

return <div className="content">

<Container>
    <Row>
        <Col>
            <h1>Training Locations</h1>
            <h2></h2>
        </Col>
    </Row>
</Container>
<Container className="box">
    <Row md={1}>
        <Col>

            <h3>Private Gym: CR 284, near Old Moulton, TX</h3>
            <p>
                My private gym is located between Flatonia and Moulton Texas in northwest Lavaca County, Texas.
            </p>
            <iframe style={{display: 'block', border: 0}}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3420.835140075531!2d-97.15723722424669!3d29.61565584555444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86437570347f35e1%3A0x65d9779f23dfe6d7!2s1021%20Co%20Rd%20284%2C%20Flatonia%2C%20TX%2078941!5e0!3m2!1sen!2sus!4v1716568889461!5m2!1sen!2sus"
                width="600" height="450" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            <p>&nbsp;</p>

            <h3>Public Gym: FayCo Sport and Spine in Flatonia, TX</h3>
            <p>

            </p>

            <iframe style={{display: 'block'}}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3466.0941724181716!2d-97.11054295901732!3d29.68804927520867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8643757d2e951971%3A0x4c69178c7af1f06!2sFayCo%20Sport%20%26%20Spine!5e0!3m2!1sen!2sus!4v1716568290341!5m2!1sen!2sus"
                width="600" height="450" style={{border: 0}} allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>

            <p>&nbsp;</p>

            <h3>Your Location</h3>
            <p>
                If you are within 30 miles of Flatonia, Texas, we can conduct one-on-one or small group training at the location of your choice.  We will use your facilities and equipment, supplemented by any portable training equipment that makes sense for the workout we have planned.  
            </p>
            <p>&nbsp;</p>

            <h3>Public Spaces</h3>
            <p>
                Public parks and community centers are a great place to train, particularly for small group training.  
                </p>
                <h4>Here are a few of the local public spaces in the area that we might leverage as a training location:
                </h4>
                <ul>    
                    <li>Moulton City Park (Moulton, TX)</li>
                    <li>McWhirter Park (Flatonia, TX)</li>
                    <li>7-Acre Park (Flatonia, TX)</li>
                    <li>Railroad Park (Flatonia, TX)</li>
                    <li>Green-Dickson Park (Shiner, TX)</li>
                    <li>Waelder City Park (Waelder, TX)</li>
                    <li>Kerr Creek Park, (Gonzales, TX)</li>
                    <li>Hallettsville City Park (Hallettsville, TX)</li>
                </ul>

        </Col>
    </Row>
</Container>
               
</div>
};

export default TrainingLocation;