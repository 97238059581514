import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import TrainingOptions from './TrainingOptions';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import React, { useEffect } from 'react';

const OneOnOneTraining = () => {
    useEffect(() => {
        document.title = 'Iron & Fury Fitness - Personal Fitness Coaching';
        document.description = 'Traditional, individualized, face-to-face, personal training will always be the optimal way for you to reap the greatest benefit from your investment in training services.';
    }, []);

return <div className="content">

<Container className="box">
    <Row md={1}>
        <Col>
            <h1>Personalized Fitness Coaching</h1>
            <h2>Fast-track your fitness goals</h2>
        </Col>
    </Row>
    <Row md={1}>
        <Col>
            <p className="first-paragraph">
                Getting in shape isn't rocket science.
                You don't need a personal trainer to get the body you want or make it perform to it's potential.
                All the relevant information is freely available on the Internet and there's no way to outsource the time investment or the effort component. &nbsp;
                <i>However</i>, if you're trying to get your fitness situation on track, having an expert to guide you and provide some accountability can be an absolute game changer.
                Think of a person (celebrity, actor, athlete, "influencer",... <i>whomever</i>) that has a body you wish you had.
                There's a 99% chance that person has a professional fitness coach.
            </p>
            <br/><br/>

            <h3>How can fitness coaching benefit you?</h3>

                <div className="indent-block">

                    <h4>Accountability &amp; Motivation</h4>
                    Motivation is great, but it's fleeting (How's that New Year's Resolution going?). When the motivation fades, you quit... unless there's something more driving you.  For some people, the fear of wasting their hard earned dollars for no gain is good enough.
                    For others, knowing there is someone else out there that will regard them with utter disdain if they deviate from their fitness plan does the trick.  When you hire a fitness coach, you get both!
                    On top of that, a coach helps you set realistic, measurable, time-constrained, fitness goals and holds you accountable to those throughout your fitness journey.
                    Once you start seeing results and smashing those goals, you'll be well on your way to turning fitness into an enjoyable habit rather than a tedious chore.
                    <br/><br/>

                    <h4>Correcting Exercise Technique</h4>
                    For any given exercise, there are a handfull of ways to perform it effectively and dozens of ways to foul it up.
                    The potential mistakes vary in severity from simply rendering the exercise less effective (wasting your time) to causing bodily injury.
                    A good coach will identify and correct flaws in your technique in real time, ensuring that you get the most out of your efforts in the gym and that you don't leave the gym in worse condition than you came in.
                    <br/><br/>

                    <h4>Expert Guidance on Exercise Selection, Progression and Programming</h4>
                    Successful physical training is about continual improvement... day over day, week over week, month over month, and year over year.
                    The training plan that is right for you now, is not going to be the plan that is right for you 3 months from now.
                    Change-up the plan too soon, or too late, or too dramatically, and results will suffer.
                    Knowing how and when to evolve the plan as you adapt to training stimulus, so you can continue to make progress, is one of the finer skills that an experienced coach brings to the table.
                    <br/><br/>

                </div>

            <h3>What type of workouts will we do?</h3>
            <p>
                There's no one training modality that works for everything or everyone. I design training programs according to each individual's unique situation and specific goals. The training programs I offer run the full-spectrum of specializations, including body re-composition (replace fat with lean mass), strength &amp; conditioning, hypertrophy (increase muscle size), powerlifting, physique/bodybuilding, cardio conditioning, agility, injury prevention &amp; recovery, sport-specific performance, and more.
            </p>
            <p>  
                As far as <i>what</i> we work out with, I have experience with a broad array of training implements and methods. I can find something that appeals to just about anyone. Once we find one or two that click, I'll help you use those to crush your fitness goals.  If you hit a plateau or get bored, I've always got alternatives to keep things fresh and fun.
            </p>

            <h4>Here are a few of the training tools/methods I might have you work with during our workouts &hellip;</h4>

            <div className="list-wrapper">
                <div className="list">
                    <div>Barbells</div>
                    <div>Dumbbells</div>
                    <div>Kettlebells</div>
                    <div>Clubbells</div>
                    <div>Steel Mace</div>
                    <div>Steps/Platforms</div>
                    <div>TRX Trainers</div>
                    <div>Swiss Ball</div>
                    <div>Balance Trainer</div>
                    <div>Battle Ropes</div>
                    <div>Tractor Tires</div>
                    <div>Resistance Bands</div>
                    <div>Medicine Balls</div>
                    <div>Boxing/Kickboxing</div>
                    <div>Calisthenics</div>
                    <div>Running</div>
                    <div>Plyometrics</div>
                    <div>Isometrics</div>
                    <div>Breathwork</div>
                </div>
            </div>
            <br/><br/>

            <h3>What are you going to do?</h3>
            <p>
                Are you ready to take your fitness journey to new heights? Contact me today for a free consultation and to explore pricing options.
            </p>

            <p style={{textAlign:'center'}}>
                <Button as={Link} to="/contact" variant="primary">Get Started Now!</Button>{' '}
            </p>
        </Col>
    </Row>
    
    
</Container>
               
</div>
};

export default OneOnOneTraining;