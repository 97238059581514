import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Alert from 'react-bootstrap/Alert';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from "react-router-dom";
import {useEffect,useState} from "react";
import parse from 'html-react-parser';


class Challenge {
    constructor(month,title,description,linkText,linkPath,image = "../bp1185231900.jpg") {
        this.month = month;
        this.title = title;
        this.description = description;
        this.linkText = linkText;
        this.linkPath = linkPath;
        this.image = image;
    }
}

const Home = () => {

    const [width, setWidth] = useState(window.innerWidth);
    const breakPoint = 639;


    useEffect(() => {
        const handleWindowResize = () => setWidth(window.innerWidth);
        window.addEventListener("resize", handleWindowResize);


        return () => window.removeEventListener("resize", handleWindowResize);
    },[]);

    useEffect(() => {
        document.title = "Iron & Fury Fitness - Personal Training Services";
        document.description = 'Fast track your fitness goals with expert guidance and coaching.  Located in NW Lavaca County, Texas – Serving Flatonia, Moulton, Shiner, Schulenburg, Hallettsville and surrounding areas.';
    }, []);

    const challenge = getChallenge();

    function getChallenge() {
        const month = 10;//new Date().getMonth()+1;

        switch (month) {
            case 1:
                return new Challenge(
                    "January",
                    "50 Burpees a Day",
                    "Loved by few and dreaded by most, burpees are a dynamic full-body exercise that will challenge you physically and mentally. The challenge here is to knock out 50 burpees a day for a full month, with a goal of being able to get them all done in a single set by they end of that period.  To begin with, try breaking them up into sets of 10 throughout the day.  Work towards getting them done in fewer, incrementally larger, sets every day until you can knock them out in a single set of 50 reps.  If you can already do that from the start, work on improving your time every day.\n Good luck!\n",
                    "How to do burpees",
                    "/instruction/burpees"
                    );
                break;
            case 2:
                return new Challenge(
                    "February",
                    "100 Push-ups",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 3:
                return new Challenge(
                    "March",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 4:
                return new Challenge(
                    "April",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 5:
                return new Challenge(
                    "May",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 6:
                return new Challenge(
                    "June",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 7:
                return new Challenge(
                    "July",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 8:
                return new Challenge(
                    "August",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 9:
                return new Challenge(
                    "September",
                    "",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
            case 10:
                return new Challenge(
                    "October",
                    "<i>Sober October!</i> No Alcohol for the Month",
                    "<p>Every now and then, it's good to hit reset on your relationship with alcohol.  This one is more of a mental challenge, but pays off big in terms of overall fitness. Prove to yourself you can go without and reap the health benefits in the process. Ditching alcohol for a month can have you noticing healthier skin, improved body composition, better sleep, and more.  Don't worry, they'll still be making booze in November.</p>",
                    "",
                    "/instruction/sober_october",
                    "../no-alcohol-lg.jpg"
                );
                break;
            case 11:
                return new Challenge(
                    "November",
                    "Happy Planksgiving!",
                    "<p>This month the challenge is to add 2 minutes to your max plank time over the course of 30 days.  On day one, hold your plank as long as you can and record your time.  Every day of the month, add 2 measly seconds to that time as your target.  At the end of the month, you'll have added 2 full minutes to your plank time!</p>",
                    "How to do a proper plank.",
                    "/instruction/plank"
                );
                break;
            case 12:
                return new Challenge(
                    "December",
                    "12 days of Liftmás",
                    "<p></p>",
                    "",
                    "/instruction/"
                );
                break;
        }
    }
return <div className="home-div">


    <Carousel className="home-carousel">
        <Carousel.Item as={Link} to="/training/strength_conditioning" >
            <img
                className="d-block w-100"
                src="one-on-one-home.jpg"
                alt="Strength & Conditioning"
            />
            <Carousel.Caption>
                <h1>Strength &amp; Conditioning</h1>
                <h2>
                    Develop strength, power, speed, endurance, and agility.
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </h2>
            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item as={Link} to="/training/body_recomposition" >
            <img
                className="d-block w-100"
                src="body-recomposition-home.jpg"
                alt="Body Recomposition"
            />
            <Carousel.Caption>
                <h1>Body Recomposition</h1>
                <h2>
                    Sculpt the body you want to see in the mirror.
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                </h2>

            </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item as={Link} to="/training/personal_defense" >
            <img
                className="d-block w-100"
                src="personal-defense-home.jpg"
                alt="Personal Defense Tactics"
            />
            <Carousel.Caption>
                <h1>Personal Defense Tactics</h1>
                <h2>
                    Develop practical, well-rounded, skills for self preservation.
                    {width > breakPoint ? (
                        <div className="phantom-alert">&nbsp;</div>
                    ) : (
                        <div></div>
                    )
                    }

                </h2>
            </Carousel.Caption>
        </Carousel.Item>

    </Carousel>

    {/*<Card className={'home-card'} >*/}
    {/*    <Card.Body>*/}
    {/*        <Card.Title>Private Fitness Coaching</Card.Title>*/}
    {/*        /!*<Card.Subtitle><span className={'t3'}>Get results quickly and safely</span></Card.Subtitle>*!/*/}
    {/*        <Card.Text>*/}
    {/*            Make the most of your workouts and fast-track your results with one-on-one coaching from an experienced certified personal trainer in the best equipped private gym in the county.*/}
    {/*            <p></p>*/}
    {/*            <p>*/}
    {/*                <h4>Let's Go!</h4>*/}
    {/*            </p>*/}
    {/*        </Card.Text>*/}
    {/*    </Card.Body>*/}
    {/*</Card>*/}
    {/*<Card className={'home-card'} >*/}
    {/*    <Card.Body>*/}
    {/*        <Card.Title><span className={'t4'}>Personal Defense Tactics</span></Card.Title>*/}
    {/*        /!*<Card.Subtitle><span className={'t4'}>Protect </span></Card.Subtitle>*!/*/}
    {/*        <Card.Text>*/}
    {/*            Develop practical, well-rounded, self-defense techniques, cherry-picked from the most effective combative arts around the globe.*/}
    {/*            You'll learn to kick, punch, grapple, escape, and employ weapons safely and effectively.*/}
    {/*        </Card.Text>*/}
    {/*    </Card.Body>*/}
    {/*</Card>*/}

    {/*<Card className={'home-card'} >*/}
    {/*    <Card.Body>*/}
    {/*        <Card.Title>*/}
    {/*            <span className={'t1'}>Look Good, </span>*/}
    {/*            <span className={'t2'}>Feel Good, </span>*/}
    {/*            <span className={'t3'}><u>Live</u> Good!</span>*/}
    {/*        </Card.Title>*/}
    {/*        /!*<Card.Subtitle className="mb-2 text-muted">Look, feel, and <i>be</i> &nbsp;your best!</Card.Subtitle>*!/*/}
    {/*        <Card.Text>*/}
    {/*            There is nothing you can invest your time and money into that will improve your quality of life more than physical fitness.*/}
    {/*        </Card.Text>*/}
    {/*    </Card.Body>*/}
    {/*</Card>*/}
</div>;
};

export default Home;

/*
Every now and then, it's good to hit reset on your relationship with alcohol. Prove to yourself you can go without and reap the health benefits in the process. Ditching alcohol for a month can have you noticing healthier skin, improved body composition, better sleep, and more.
*/