
import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Bio from "./pages/Bio";
import Blog from "./pages/Blog";
import BlogIntermittentFasting from "./pages/blog/IntermittentFasting";
import OneOnOneTraining from "./pages/OneOnOneTraining";
import PersonalDefense from "./pages/PersonalDefense";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import TrainingLocation from "./pages/TrainingLocation";
import StrengthAndConditioning from "./pages/StrengthAndConditioning";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/intermittent_fasting" element={<BlogIntermittentFasting />} />
          <Route path="/about" element={<Bio />} />
          <Route path="/training/strength_conditioning" element={<OneOnOneTraining />} />
          <Route path="/training/body_recomposition" element={<OneOnOneTraining />} />
          <Route path="/training/personal_defense" element={<PersonalDefense />} />
          <Route path="/training/location" element={<TrainingLocation />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>

    
  );
}

export default App;
