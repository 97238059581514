
import { Outlet, Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Copyright from "../Copyright";

const Layout = () => {
  return (
    
      <div className="App">
        <nav>
            <Navbar expand="sm" className="bg-body-tertiary" bg="dark" data-bs-theme="dark">
                <Container>
                    <Navbar.Brand  as={Link} to="/" eventKey="/home" title="Iron & Fury">
                        {/*<img src="logo.png"/>*/}
                        <div className="logo">
                            <div className="company-name">Iron &amp; Fury</div>
                            <div className="company-name-sub">FITNESS</div>
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end navlinks">
                        <Nav>
                            <Nav.Link as={Link} to="/about" eventKey="/about" title="About">About</Nav.Link>
                            <NavDropdown title="Training" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/training/strength_conditioning">Personalized Fitness Coaching</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/training/personal_defense">Personal Defense Tactics</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={Link} to="/training/location">Training Locations</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </nav>
      <div>
        <Outlet />
          <div className="footer">

              <div className="locations">
                  Flatonia, TX
                  &nbsp;&nbsp;
                  <span className="star bi bi-star" style={ {color: '#FFF'} }></span>
                  &nbsp;&nbsp;
                  Moulton, TX
                  &nbsp;&nbsp;
                  <span className="star bi bi-star" style={ {color: '#FFF'} }></span>
                  &nbsp;&nbsp;
                  Shiner, TX
              </div>

              <div className="phone">
                  (361) 865-4144
              </div>

              <Copyright></Copyright>
              <div>
                  <a href="https://www.facebook.com/ironandfury" target="_blank"><span className="facebook bi bi-facebook"></span></a>
                  &nbsp; &nbsp;
                  <a href="https://www.instagram.com/ironandfury/" target="_blank"><span className="instagram bi bi-instagram"></span></a>

              </div>
              <p>&nbsp;</p>
          </div>
      </div>
    </div>

    
  )
};

export default Layout;

