import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import TrainingOptions from './TrainingOptions';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import React, { useEffect } from 'react';

const PersonalDefenseTactics = () => {
    useEffect(() => {
        document.title = 'Iron & Fury Fitness - Personal Defense Tactics';
        document.description = 'Develop practical, well-rounded, self-defense techniques, cherry-picked from the most effective combative arts around the globe.';
    }, []);

    return <div className="content">

        <Container className="box">
            <Row md={1}>
                <Col>
                    <h1>Personal Defense Tactics</h1>
                    <h2>It's a wild world out there. Be a terrible victim.</h2>
                </Col>
            </Row>
            <Row md={1}>
                <Col>
                    <p>
                        Personal defense tactics training is designed to equip individuals with the skills and confidence needed to protect themselves in the ugliest real-world scenarios.
                        Whether you are a beginner looking to learn basic self-defense techniques or an advanced practitioner aiming to hone your skills, this comprehensive program offers practical, hands-on training tailored to your needs and abilities.
                    </p>
                    <p>
                        Develop practical, well-rounded, self-defense techniques, cherry-picked from the most effective combative arts around the globe.
                        You'll learn to kick, punch, grapple, escape, and employ weapons safely and effectively.
                    </p>
                    <p>&nbsp;</p>
                    <h3>What kind of skills will we train?</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="list-wrapper">
                        <div className="list">
                            <div>Striking Mechanics</div>
                            <div>Footwork &amp; Positioning</div>
                            <div>Controlling Distance</div>
                            <div>Ground Fighting &amp; Sumbissions</div>
                            <div>Joint Manipulation</div>
                            <div>Edged &amp; Impact Weapons</div>
                            <div>Improvised Weapons</div>
                            <div>Firearms Handling &amp; Safety</div>
                            <div>Shooting Skills</div>
                        </div>
                    </div>
                    <p>&nbsp;</p>
                </Col>
            </Row>

            <Row>
                <Col>

                    <h3>Let's do this!</h3>

                    <p>
                        Sign-up for personal defense tactics training and take the first step towards empowering yourself with the knowledge and skills to keep youself and your loved ones safe.
                        {/*Whether you are looking to enhance your personal security, build confidence, or learn effective self-defense techniques, this comprehensive program is here to support you every step of the way.*/}
                    </p>

                    <p>&nbsp;</p>
                    <p style={{textAlign:'center'}}>
                        <Button as={Link} to="/contact" variant="primary">Get Started Now!</Button>{' '}
                    </p>
                </Col>
            </Row>


        </Container>

    </div>
};

export default PersonalDefenseTactics;