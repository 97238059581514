import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import {useEffect} from "react";

const Bio = () => {

    useEffect(() => {
        document.title = "Iron & Fury Fitness - About";
        document.description = 'I\'m Jason Patton, certified personal trainer and owner of Iron &amp; Fury Fitness.';
    }, []);

return  <div className="content">
    <Container className="box">
        <Row md={1}>
            <Col>
                <h1>About Me</h1>
                <h3>Hello!</h3>
                <Image src="JasonPatton.jpg" className="bio-pic" rounded fluid />
                <p>
                    I'm Jason Patton, certified personal trainer and owner of Iron &amp; Fury Fitness.
                </p>
                <p>
                    Physical fitness has long been an essential part of my life, but it wasn't ever the end game.  Exercise was just something I did to be able to enjoy competitive and recreational activities (SCUBA divng, martial arts, motorcycling, bowhunting, et al) at the level that I wanted, avoid injury, offload stress, and combat the harsh effects of advancing age and modern comfort.  The nutrition and exercise themselves weren't ever what was important to me. Those were just prerequisites for being in condition for doing the things that were.  Nonetheless, I always put in the research, experimentation, and repetition to get what I needed out of it. 
                </p>
                <p>    
                    Back before the Internet made <i>experts</i> out of everyone, I figured out what does and doesn't work in the gym the long/hard way... by trial and error. I can only recommend this path for those of you who like reading (a lot), using yourself as a guinea pig for innumerable wacky theories, tolerate injury well, and have a decade or so to get the results you want.
                </p>
                <p> 
                     The success I eventually had in my training efforts did not go unnoticed. I found myself getting lots of requests to become training partners with other people and fielding a lot of questions about health and fitness.  While I don't really like to work out with a training partner (slower pace, too much talking, scheduling conflicts, et al), I was always happy to answer questions and help other people.  I decided that if I was going to be giving other people advice that affects their wellbeing, I had better know what I was talking about. As a result, my research efforts in the realm of health and fitness expanded and intensified. In 2016 I realized that I had unintentionally created a second job for myself&mdash;one that I wasn't getting paid for&mdash;so I decided to go legit and get certified as a personal trainer.
                </p>
                <p>
                    Working with others to help improve their quality of life has not only proven extremely satisfying, but it also serves as a pleasant distraction from my day job. Outside of training and coaching others, I hold down a full-time job as a Data Architect (nobody ever guesses that one) for a Fortune 500 corporation. I spend much of my time doing the same sorts of thing that good many of my clients do &mdash; holed up in my office, banging away behind a computer screen, attending meetings, juggling phone calls, and the like. Kids? Got 'em. Bad habits? I've got those too.  Injuries?  I've had my fair share.  Having four dozen laps around the sun, I understand the obstacles life throws at people. More importantly, I understand the importance of having a sustainable work/life/fitness balance. I've had great success implementing strategies to maintain that balance in my own life, and I'm here to help my clients do the same.
                </p>
                <p>
                    I invite you to join me in the pursuit of a healthier, stronger, more capable, and better looking version of yourself.  There's no time like the present to get started!
                </p>

                <p className="greeting">
                    &ndash; Jason Patton, CPT
                </p>

                <p>&nbsp;</p>
                <h4>
                    Qualifications
                </h4>
                <ul>
                    <li>NASM Certified Personal Trainer (CPT)</li>
                    {/*<li>NASM Certified Nutrition Coach (CNC)</li>*/}
                    <li>TRX Suspension Trainer Certified</li>
                    <li>First Aid, CPR, and AED Certified</li>
                </ul>
            </Col>
        </Row>
    </Container>
    </div>;
};

export default Bio;